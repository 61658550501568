<template>
  <div id="ejcon" class="ejcon"  ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
<!-- 头部 -->
         <div class="top-header">
            <dv-decoration-8 class="header-left-decoration" />
            <dv-decoration-5 class="header-center-decoration" dur="3" />
            <dv-decoration-8 class="header-right-decoration" :reverse="true" />
            <div class="center-title">职工电竞比赛</div>
          </div>
<div class="return" @click="returnBack">
            <img src="@/assets/return.png" alt="">
          </div>

  <!-- 年级 -->
  <div class="classInfo">
      <dv-border-box-6 :color="['#155e9f', '#0b6ec7']">
        
        <div class="classInfo_Con">
 <dv-scroll-board class="dv-scr-board" :config="config" />
         
          
        </div>
      </dv-border-box-6>
    
  </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";


export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      digitalFlopData: [],
      config: {
        header: ['<span style="color:#9abeff;font-weight:bold">项目</span>', 
        '<span style="color:#9abeff;font-weight:bold;">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold;">参赛时间</span>', 
        '<span style="color:#9abeff;font-weight:bold">赛道</span>',
        '<span style="color:#9abeff;font-weight:bold">距离/m</span>'],
        data: [
          ['挑战9.83s','王一', "2023.04.05", "1","90"],
          ['挑战9.83s','李三', "2023.04.02", "2","80"],
          ['挑战9.83s','张四', "2023.04.06", "3","93"],
          ['挑战9.83s','曹一', "2023.04.07", "4","92"],
          ['挑战9.83s','曹二', "2023.04.08", "1","92"],
          ['挑战9.83s','曹三', "2023.04.09", "2","93"],
          ['挑战9.83s','曹四', "2023.04.10", "3","81"],
          ['挑战9.83s','曹五', "2023.04.11", "4","82"],
          ['挑战9.83s','曹六', "2023.04.12", "1","82"],
          ['挑战9.83s','曹七', "2023.04.13", "2","83"],
          ['挑战9.83s','曹八', "2023.04.14", "3","81"],
          ['挑战9.83s','曹玖', "2023.04.15", "4","88"],
          ['挑战9.83s','曹拾', "2023.04.16", "1","88"],
          ['挑战9.83s','李一', "2023.04.17", "2","89"],
          ['挑战9.83s','李二', "2023.04.18", "3","87"],
          ['挑战9.83s','李四', "2023.04.19", "4","88"],
          ['挑战9.83s','李五', "2023.04.20", "1","85"],
          ['挑战9.83s','李六', "2023.04.21", "2","88"],
          ['挑战9.83s','李七', "2023.04.22", "3","84"],
          ['挑战9.83s','李八', "2023.04.23", "4","88"],
        ],
         rowNum: 9, //表格行数
        headerHeight: 80,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
        align: ['left','left'],
        // columnWidth: [230,210,100,160,160,240,360],
      }
    }
  },
  components: {
   
  },
  mounted() {
    this.cancelLoading()
    
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
  //  加载动画
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

   
    randomExtend (minNum, maxNum) {
      if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
      } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      }
    },
     returnBack(){
      this.$router.go(-1)
    },

  }
}
</script>

<style lang="scss" scoped>
.title{
  padding: 30px 24px;
    font-size: 22px;
}
.bg{
  padding: 0;
}
.ejcon {
  color: #d3d6dd;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;

  .host-body {
.top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 16px;
  margin-bottom: 16px;

  .header-center-decoration {
    width: 46%;
    height: 60px;
    margin-top: 20px;
  }

  .header-left-decoration, .header-right-decoration {
    width: 25%;
    height: 60px;
  }

  .center-title {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 28px;
    transform: translateX(-50%);
    letter-spacing:8px;
    color: #0ebe9b;
  }
}
.return{
  position: fixed;
  z-index: 9999;
  cursor: pointer;
  left: 20px;
  top: 92px;
  width: 80px;
  height: 80px;
  background: rgba(0, 250, 255, 0.7);
  border-radius: 50%;
  border: 1px solid #0f319b;
  img{
    display: block;
    text-align: center;
    width: 36px;
    margin: 24px auto;
  }
}
  }

  
.classInfo{
  margin: 50px auto 0;
  width: 1400px;
  height: 840px;
  .classInfo_Con{
    width: 100%;

    margin: 0 auto;
      .dv-scr-board {
      //  margin: 30px 0;
       padding: 30px;
      //  width: 100%;
      height: 780px;
      color: #d4eaf6;
      ::v-deep .header-item{
        font-size: 24px !important;
      }
      ::v-deep .row-item{
        height: 78px !important;
        line-height:78px !important;
        font-size:20px !important;
        color: #9abeff !important;
        border-bottom: 1px solid #02365a !important;
      }
      
    }
    
  }
}

}
</style>
